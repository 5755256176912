import { HStack } from '@chakra-ui/react';
import { UseTableFiltersType } from 'hooks/useTableFilters';
import { CustomerPopover } from 'pages/Customers/components/customer-filter/customer-popover';
import { CustomerSearch } from 'pages/Customers/components/CustomerSearch';

type CustomerTrackingToolbarProps = {
  tableFilters: UseTableFiltersType;
};

export const CustomerTrackingToolbar = ({ tableFilters }: CustomerTrackingToolbarProps) => {
  return (
    <HStack>
      <CustomerSearch tableFilters={tableFilters} />
      <CustomerPopover tableFilters={tableFilters} />
    </HStack>
  );
};
