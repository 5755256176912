import { Badge, Table, Text } from '@chakra-ui/react';
import { KDataTable } from 'components/table/data-table';
import { TableEmptyState } from 'components/table-empty-state/table-empty-state';
import { TableRowSkeleton } from 'components/ui/table-row-skeleton';
import { useSortableTable } from 'hooks/useSortableTable';
import { UseTableFiltersType } from 'hooks/useTableFilters';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomerInstance } from 'types/shared-types';
import { getCountryDisplay } from 'utils';

type CustomerTablePropsType = {
  data: CustomerInstance[];
  tableFilters: UseTableFiltersType;
  isFilterApplied: boolean;
  isSearchApplied: boolean;
  isPending: boolean;
};

const TABLE_HEAD = [
  'ID',
  'Customer Name',
  'email',
  'country',
  'Jurisdiction',
  'city',
  'postal code',
  'street',
  'status',
];
const DEFAULT_VISIBLE_COLUMNS = TABLE_HEAD;
const SORT_HEADERS = ['street', 'city', 'Jurisdiction', 'postal code', 'country', 'status'];
const ALL_SORTABLE_FIELDS = ['street_1', 'city', 'state', 'postal_code', 'country', 'status'];
const FIELD_MAPPING = {
  street: 'street_1',
  'postal code': 'postal_code',
  city: 'city',
  Jurisdiction: 'state',
  country: 'country',
  status: 'status',
};
const COLUMN_WIDTHS = {
  ID: '100px',
  'Customer Name': '150px',
  email: '150px',
  country: '80px',
  Jurisdiction: '100px',
  city: '100px',
  'postal code': '100px',
  street: '100px',
  status: '80px',
};

export const CustomerTable = ({
  data,
  isFilterApplied,
  isSearchApplied,
  tableFilters,
  isPending,
}: CustomerTablePropsType) => {
  const isDataEmpty = !data || data.length === 0;
  const { order_by, setFilters } = tableFilters;
  const [visibleColumns, setVisibleColumns] = useState<string[]>(DEFAULT_VISIBLE_COLUMNS);
  const handleToggleColumn = (columns: string[]) => {
    setVisibleColumns(columns);
  };

  const { handleSort, getSortIcon } = useSortableTable({
    initialOrderBy: order_by,
    allSortableFields: ALL_SORTABLE_FIELDS,
    fieldMapping: FIELD_MAPPING,
    setFilters,
  });

  if (isDataEmpty && !isPending) {
    return (
      <TableEmptyState tableName="Customers" isFilterApplied={isFilterApplied} isSearchApplied={isSearchApplied} />
    );
  }

  return (
    <KDataTable
      headers={TABLE_HEAD}
      defaultVisibleColumns={visibleColumns}
      onVisibleColumnsChange={handleToggleColumn}
      showColumnFilter
      sortHeaders={SORT_HEADERS}
      getSortIcon={getSortIcon}
      handleSort={handleSort}
      columnWidths={COLUMN_WIDTHS}
      isPending={isPending}
    >
      {data?.map((row: any, index: number) => (
        <CustomerTableRow key={row.id ?? index} {...row} visibleColumns={visibleColumns} />
      ))}
      {isPending && <TableRowSkeleton length={25} columns={visibleColumns} />}
    </KDataTable>
  );
};

type CustomerTableRowProps = CustomerInstance & {
  visibleColumns: string[];
};

const CustomerTableRow = ({
  id,
  external_id,
  email,
  street_1,
  city,
  state,
  postal_code,
  country,
  status,
  name,
  visibleColumns,
}: CustomerTableRowProps) => {
  const navigate = useNavigate();

  const isActive = status === 'ACTIVE';

  const handleRowClick = () => {
    navigate(`/customers/${id}`);
  };

  return (
    <Table.Row
      onClick={handleRowClick}
      _hover={{
        bg: '#EFEFF3',
        cursor: 'pointer',
      }}
    >
      {visibleColumns?.includes('ID') && (
        <Table.Cell width={{ sm: '150px' }}>
          <Text>{external_id}</Text>
        </Table.Cell>
      )}
      {visibleColumns?.includes('Customer Name') && (
        <Table.Cell width={{ sm: '150px' }}>
          <Text lineClamp={1}>{name}</Text>
        </Table.Cell>
      )}
      {visibleColumns?.includes('email') && (
        <Table.Cell width={{ sm: '130px' }}>
          <Text>{email}</Text>
        </Table.Cell>
      )}
      {visibleColumns?.includes('country') && (
        <Table.Cell width={{ sm: '80px' }}>
          <Text>{getCountryDisplay(country)}</Text>
        </Table.Cell>
      )}
      {visibleColumns?.includes('Jurisdiction') && (
        <Table.Cell width={{ sm: '110px' }}>
          <Text>{state}</Text>
        </Table.Cell>
      )}
      {visibleColumns?.includes('city') && (
        <Table.Cell width={{ sm: '80px' }}>
          <Text>{city}</Text>
        </Table.Cell>
      )}
      {visibleColumns?.includes('postal code') && (
        <Table.Cell>
          <Text>{postal_code}</Text>
        </Table.Cell>
      )}
      {visibleColumns?.includes('street') && (
        <Table.Cell width={{ sm: '150px' }}>
          <Text>{street_1}</Text>
        </Table.Cell>
      )}
      {visibleColumns?.includes('status') && (
        <Table.Cell width={{ sm: '80px' }}>
          {isActive ? <Badge colorPalette={'green'}>Active</Badge> : <Badge colorPalette={'gray'}>Inactive</Badge>}
        </Table.Cell>
      )}
      <Table.Cell></Table.Cell> {/* Header showing ColumnFilter so tr need to keep proper alignment */}
    </Table.Row>
  );
};
