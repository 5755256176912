import { TableFilterPopover } from 'components/filter';
import { UseTableFiltersType } from 'hooks/useTableFilters';
import { useState } from 'react';

import { CustomerFilter } from './customer-filter';

type CustomerPopoverProps = {
  tableFilters: UseTableFiltersType;
};
export const CustomerPopover = ({ tableFilters }: CustomerPopoverProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { country, state } = tableFilters;
  const hasFilter = !!(country || state);
  return (
    <TableFilterPopover hasFilter={hasFilter} open={open} onOpenChange={({ open }) => setOpen(open)}>
      <CustomerFilter
        onClose={() => {
          setOpen(false);
        }}
        tableFilters={tableFilters}
      />
    </TableFilterPopover>
  );
};
