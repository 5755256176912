import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import { AccordionItem, AccordionItemContent, AccordionItemTrigger, AccordionRoot } from 'components/ui/accordion';
import { Button } from 'components/ui/button';
import { NativeSelectField, NativeSelectRoot } from 'components/ui/native-select';
import { useLocationData } from 'hooks/useLocationData';
import { useStateOptions } from 'hooks/useStateOptions';
import { UseTableFiltersType } from 'hooks/useTableFilters';
import { ChangeEvent, useEffect, useState } from 'react';

type CustomerFilterProps = {
  tableFilters: UseTableFiltersType;
  onClose: () => void;
};

export const CustomerFilter = ({ onClose, tableFilters: { state, country, setFilters } }: CustomerFilterProps) => {
  const { countries } = useLocationData();
  const { stateOptions, fetchStateOptions } = useStateOptions();
  const [selectedState, setSelectedState] = useState<string>(state || '');
  const [selectedCountry, setSelectedCountry] = useState<string>(country || '');

  useEffect(() => {
    if (selectedCountry) {
      fetchStateOptions(selectedCountry);
    }
  }, [selectedCountry, fetchStateOptions]);

  const onCountryChange = async (e: ChangeEvent<HTMLSelectElement>) => {
    const countryCode = e.target.value;
    setSelectedCountry(countryCode);
    setSelectedState('');
  };

  const handleSave = () => {
    const filterParams = {
      country: selectedCountry || undefined,
      state: selectedState || undefined,
    };
    setFilters(filterParams);
    onClose();
  };

  const handleClear = () => {
    setSelectedCountry('');
    setSelectedState('');
    setFilters({ country: undefined, state: undefined, page: 1, size: 25 });
    onClose();
  };

  const hasFilter = selectedCountry || selectedState;

  return (
    <Stack>
      <HStack justifyContent={'space-between'}>
        <Text fontSize={'18px'} fontWeight={500}>
          Filters
        </Text>
        {hasFilter && (
          <Text fontSize={'12px'} color={'#4285F4'} cursor={'pointer'} onClick={handleClear}>
            Clear All Filters
          </Text>
        )}
      </HStack>
      <AccordionRoot variant="plain" multiple>
        <AccordionItem value="country">
          <AccordionItemTrigger>
            <Box as="span" fontSize={'sm'} fontWeight={'medium'} flex="1" textAlign="left">
              Country
            </Box>
          </AccordionItemTrigger>
          <AccordionItemContent mb={2}>
            <NativeSelectRoot>
              <NativeSelectField
                id="country"
                name="country"
                value={selectedCountry}
                onChange={onCountryChange}
                placeholder="Select"
              >
                {countries.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </NativeSelectField>
            </NativeSelectRoot>
          </AccordionItemContent>
        </AccordionItem>
        <AccordionItem value="jurisdiction">
          <AccordionItemTrigger>
            <Box as="span" fontSize={'sm'} fontWeight={'medium'} flex="1" textAlign="left">
              Jurisdiction
            </Box>
          </AccordionItemTrigger>
          <AccordionItemContent>
            <NativeSelectRoot>
              <NativeSelectField
                id="state_code"
                name="state_code"
                value={selectedState}
                onChange={e => setSelectedState(e.target.value)}
                placeholder="Select"
              >
                {stateOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </NativeSelectField>
            </NativeSelectRoot>
          </AccordionItemContent>
        </AccordionItem>
      </AccordionRoot>

      <HStack direction="row" justifyContent={'space-around'} gap="18px" mt={4}>
        <Button variant="outline" color="secondary" onClick={onClose} width={'132px'} height={'32px'}>
          Cancel
        </Button>
        <Button
          variant="solid"
          color="primary"
          onClick={handleSave}
          width={'132px'}
          height={'32px'}
          disabled={!hasFilter}
        >
          Apply
        </Button>
      </HStack>
    </Stack>
  );
};
