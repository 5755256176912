import { useCallback, useMemo, useState } from 'react';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import { UseTableFilters } from 'types/shared-types';
import { getFieldSortOrder } from 'utils/utils';

type FieldSortOrder = 'asc' | 'desc';
type FieldMapping = Record<string, string>;

export const useSortableTable = ({
  initialOrderBy = '',
  allSortableFields,
  fieldMapping,
  setFilters,
}: {
  initialOrderBy?: string;
  allSortableFields: string[];
  fieldMapping: FieldMapping;
  setFilters: (newFilters?: UseTableFilters) => void;
}) => {
  const [orderBy, setOrderBy] = useState(initialOrderBy);

  const createSortOrders = useCallback(
    (orderByString: string) => {
      const fields = orderByString.split(',').map(order => order.trim());
      return allSortableFields.reduce(
        (acc, field) => {
          acc[field] = getFieldSortOrder(fields, field);
          return acc;
        },
        {} as Record<string, FieldSortOrder>
      );
    },
    [allSortableFields]
  );

  const sortOrders = useMemo(() => createSortOrders(orderBy), [orderBy, createSortOrders]);

  const getSortOrder = useCallback((field: string) => sortOrders[field] || 'asc', [sortOrders]);

  const handleSort = useCallback(
    (field: string) => {
      const actualField = fieldMapping[field] || field;
      const sortOrder = getSortOrder(actualField);
      const newSortOrder = sortOrder === 'asc' ? '-' : '';
      const sortedFields = allSortableFields.map(sortableField => {
        let prefix = '';
        if (sortableField === actualField) {
          prefix = newSortOrder;
        } else if (getSortOrder(sortableField) !== 'asc') {
          prefix = '-';
        }
        return `${prefix}${sortableField}`;
      });
      const newOrderByArray = sortedFields.filter(Boolean);
      const newOrderBy = newOrderByArray.join(',');
      setOrderBy(newOrderBy);
      setFilters({ order_by: newOrderByArray });
      return newOrderBy;
    },
    [allSortableFields, fieldMapping, getSortOrder, setFilters]
  );

  const getSortIcon = useCallback(
    (field: string) => {
      const actualField = fieldMapping[field] || field;
      const sortOrder = getSortOrder(actualField);
      return sortOrder === 'asc' ? <MdArrowDropUp /> : <MdArrowDropDown />;
    },
    [fieldMapping, getSortOrder]
  );

  return {
    orderBy,
    getSortOrder,
    handleSort,
    getSortIcon,
  };
};
