import { HStack, Stack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { CUSTOMERS_STATE_KEY, getCustomers } from 'apis/customers';
import { getInitialImportStatus, TRANSACTION_STATE_KEY } from 'apis/transaction-api';
import AppHeader from 'app/app-header';
import { CustomerTrackingToolbar } from 'app-header-toolbar/customer-tracking-toolbar';
import AlertBanner from 'components/alert/alert';
import { PaginationButtons } from 'components/pagination/pagination-buttons';
import { useOrg } from 'hooks/useOrg';
import { DEFAULT_PAGE_SIZE, useTableFilters } from 'hooks/useTableFilters';

import { CustomerTable } from './components/table';

const Customers = () => {
  const tableFilters = useTableFilters({
    order_by: ['street_1', 'city', 'state', 'postal_code', 'country', 'status'],
  });

  const { page, size, order_by, search_query, country, state, setFilters } = tableFilters;

  const { orgId } = useOrg();

  const { isPending, data } = useQuery({
    queryKey: [CUSTOMERS_STATE_KEY, orgId, page, size, order_by, search_query, country, state],
    queryFn: async () => {
      const { data } = await getCustomers(orgId, { page, size, order_by, search_query, country, state });
      return data;
    },
  });

  const { isPending: isInitialStatusLoading, data: statusData } = useQuery({
    queryKey: [TRANSACTION_STATE_KEY, 'TRANSACTION_STATUS', orgId],
    queryFn: async () => {
      const res = await getInitialImportStatus(orgId);
      return res.data;
    },
  });

  const customerData = data?.items || [];
  const totalPages = data?.pages ?? 0;
  const isPaginationEnable = customerData.length > 0 && (data?.total ?? 0) > DEFAULT_PAGE_SIZE;
  const isAllPending = isPending || isInitialStatusLoading;

  return (
    <Stack>
      <HStack gap={1} justifyContent={'space-between'}>
        <AppHeader />
        <CustomerTrackingToolbar tableFilters={tableFilters} />
      </HStack>

      {!statusData?.initial_import_complete_status && !isPending && (
        <AlertBanner
          message="Transaction import is still in progress, so the numbers may not be the latest. We’ll email you once the
            import is complete."
          width={{ xl: '75%' }}
        />
      )}

      <CustomerTable
        tableFilters={tableFilters}
        data={customerData}
        isFilterApplied={!!(country || state)}
        isSearchApplied={!!search_query}
        isPending={isAllPending}
      />
      {isPaginationEnable && (
        <PaginationButtons size={size} currentPage={page} setFilters={setFilters} totalPages={totalPages} />
      )}
    </Stack>
  );
};

export default Customers;
